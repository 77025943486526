.top-navigator {
  width: 100%;
  max-width: 100%;
  height: 70px;
  vertical-align: middle;
  padding: 0 20px;
}

.top-navigator-logo {
  font-size: 30px;
  font-weight: 700;
  height: 45px;
  display: flex;
  align-items: center;
}

.top-navigator-logo span {
  animation: rainbow 2.5s linear;
  animation-iteration-count: infinite;
}

.top-navigator-logo svg {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  transition: transform .7s ease-in-out;
}

.top-navigator-logo svg:hover {
  transform: rotate(180deg);
}

@keyframes rainbow {
  100%,
  0% {
    color: rgb(255, 0, 0);
  }
  8% {
    color: rgb(255, 127, 0);
  }
  16% {
    color: rgb(255, 255, 0);
  }
  25% {
    color: rgb(127, 255, 0);
  }
  33% {
    color: rgb(0, 255, 0);
  }
  41% {
    color: rgb(0, 255, 127);
  }
  50% {
    color: rgb(0, 255, 255);
  }
  58% {
    color: rgb(0, 127, 255);
  }
  66% {
    color: rgb(0, 0, 255);
  }
  75% {
    color: rgb(127, 0, 255);
  }
  83% {
    color: rgb(255, 0, 255);
  }
  91% {
    color: rgb(255, 0, 127);
  }
}

.top-navigator-search {
  height: 36px;
  border-radius: 18px;
  background-color: #e6e6e6;
  margin-right: 10px;
  padding: 10px;
  border: 2px #e6e6e6 solid;
}

.top-navigator-search.empty > svg:nth-child(3) {
  visibility: hidden;
}

.top-navigator-search.focused {
  border: 2px #ff4000 solid;
}

.top-navigator-search > * {
  margin: 5px;
}

.top-navigator-search > input {
  width: 400px;
  border: 0;
  background-color: inherit;
  outline: none;
  height: 30px;
}

.top-navigator-dropdown {
  min-width: 150px;
}

.top-navigator-dropdown > button {
  height: 40px;
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
}

.top-navigator-dropdown > button:hover {
  background-color: #cccccc;
}

.top-navigator-dropdown > button > :nth-child(1) {
  float: left;
  width: 50px;
}

.top-navigator-dropdown > button::after {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0;
  margin-left: auto;
  display: inline-flex;
  float: right;
}

.top-navigator-dropdown .dropdown-item:hover {
  color: white;
  background-color: #ff4000;
}

.top-navigator-user {
  min-width: 200px;
}

.top-navigator-user > * {
  float: right;
}
