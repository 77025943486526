.store-card > img {
  height: 150px;
  object-fit: cover;
}

.store-card {
  transition: transform .5s;
}

.store-card:hover {
  transform: scale(1.05);
}
