.page-container {
  margin: 20px auto;
  padding: 0 5%;
}

.quick-bite-button {
  display: flex;
  background-color: white;
  transition: background-color .2s;
  height: 40px;
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 5px;
  padding: 5px;
  margin: 0 5px;
}

.quick-bite-button:hover {
  background-color: #cccccc;
}

.quick-bite-button > svg {
  width: 24px;
  height: 24px;
}

.quick-bite-button.quick-bite-bg {
  background-color: #ff4000;
  color: white;
}

.quick-bite-button.quick-bite-bg:hover {
  background-color: #cc3300;
}

.horizontal-separator {
  width: min(calc(100vw - 40px), 100%);
  margin: 0 auto;
}

.horizontal-separator.vertically-spaced {
  margin-top: 20px;
  margin-bottom: 20px;
}

.quick-bite-spinner {
  color: #ff4000;
}

.star > span {
  margin-right: 5px;
}

.star > * {
  display: inline-flex;
  float: right;
}

.disabled:hover {
  cursor: not-allowed;
}

.clickable:not(.disabled):hover {
  cursor: pointer;
}

.themed-content, .themed-content svg {
  transition: fill .1s, color .2s, background-color .2s;
}

.themed-content:not(.disabled):hover, .themed-content:not(.disabled):hover svg {
  color: #ff4000;
  fill: #ff4000;
}

.invisible-link,
.invisible-link:hover {
  text-decoration: inherit;
  color: inherit;
  width: fit-content;
}

.snack-bar {
  margin-top: 200px;
  color: white;
}

.snack-bar .toast-body {
  border-radius: 5px;
  font-size: 1rem;
  width: 400px;
}

.snack-bar-body-success {
  background-color: #2e7d32;
}

.snack-bar-body-warning {
  background-color: #ed6c02;
}

.snack-bar-body-error {
  background-color: #d32f2f;
}

.snack-bar-body-info {
  background-color: #0288d1;
}

.snack-bar svg {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}