.login-page-ad-container,
.login-page-login-container {
  width: 50%;
  height: 100%;
  position: fixed;
}

.login-page-ad-container {
  left: 0;
  background-color: #ff4000;
}

.login-page-login-container {
  right: 0;
}

.login-page-ad-content-wrapper {
  margin-left: auto;
  margin-right: auto;
  user-select: none;
  animation: 0.5s ease-in-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100;
  }
}

.login-page-ad-content-wrapper span {
  color: white;
  font-size: 80px;
  font-weight: 700;
  text-shadow: 5px 5px black;
  transition: text-shadow 0.2s;
}

.login-page-ad-content-wrapper span:hover {
  text-shadow: 7px 7px #222222;
}

.login-page-ad-content-wrapper svg {
  width: 80px;
  height: 80px;
  fill: white;
  margin-right: 30px;
  transition: transform 0.7s ease-in-out;
}

.login-page-ad-content-wrapper svg:hover {
  transform: rotate(180deg);
}

.login-page-ad-content-wrapper p {
  font-size: 30px;
  color: white;
  margin-top: 100px;
  font-style: italic;
  text-shadow: 2px 2px black;
  transition: text-shadow 0.2s;
}

.login-page-ad-content-wrapper p:hover {
  text-shadow: 3px 3px #222222;
}

.login-page-tabbed-wrapper {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
  min-width: 300px;
  min-height: 500px;
}

.login-page-tabbed-wrapper .nav-link {
  color: #ff4000;
}

.login-page-tabbed-wrapper .quick-bite-button {
  width: 100%;
  margin: 30px 0;
  padding: 20px 0;
  font-size: 1.25rem;
  justify-content: center;
}

.profile-page-container {
  padding: 0 15%;
}

.profile-page-title {
  font-size: 40px;
  font-weight: 700;
}

.profile-page-subtitle {
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0;
}

.profile-page-subtitle-button-group {
  display: flex;
  margin-left: auto;
  margin-right: -5px;
}

.profile-page-form .col-form-label {
  text-align: end;
}

.profile-page-past-order-entry {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.profile-page-past-order-entry:hover {
  background-color: #e6e6e6;
}

.profile-page-past-order-entry img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 2px;
}