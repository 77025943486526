.store-page-banner {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.store-page-name {
  font-size: 40px;
  font-weight: 700;
}

.store-page-description {
  font-size: 16px;
}

.store-page-main {
  margin-top: 30px;
  vertical-align: top;
  display: flex;
  height: 100%;
  align-items: flex-start;
}

.store-page-sidebar {
  width: 300px;
  position: sticky;
  top: 20px;
}

.store-page-sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.store-page-sidebar li {
  width: fit-content;
  font-size: 20px;
  margin: 0 0 5px;
}

.store-page-sidebar li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.store-page-sidebar li.current-category {
  text-decoration: underline;
  font-weight: 700;
}

.store-page-catalogue {
  display: inline-block;
}

.store-page-catalogue-category:not(:nth-last-child(1)) {
  margin-bottom: 20px;
}

.store-page-catalogue-category > span {
  font-size: 30px;
  font-weight: 700;
  margin-left: 12px;
}

.store-page-catalogue-category > .container {
  margin-top: 5px;
}

.item-card > img {
  height: 150px;
  object-fit: cover;
}

.item-card {
  transition: transform .5s;
}

.item-card:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.item-card-description {
  color: #888888;
  font-size: 14px;
}

.item-modal {
  user-select: none;
}

.item-modal-header {
  border: none;
}

.item-modal-image {
  width: 60%;
  max-height: 30vh;
  object-fit: cover;
  border-radius: 5px;
  margin: 10px 20%;
}

.item-modal-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}

.item-modal-price {
  font-size: 18px;
  font-weight: 600;
}

.item-modal-footer {
  justify-content: space-between;
}

.item-modal-update-count-container {
  display: flex;
  vertical-align: middle;
}

.item-modal-update-count-container > * {
  width: 25px;
  height: 25px;
  margin: auto 10px;
}

.item-modal-update-count-container > span {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-modal-add-button {
  min-width: 200px;
  padding: 0 10px;
  justify-content: center;
}
